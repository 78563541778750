<template>
  <dog-dialog
    ref="hfDialog"
    title="设置分类"
    @loading-confirm="confirm"
    @closed="closed"
    width="800px"
  >
    <div class="flex_row dialog_zone_scroll setClassDialog">
      <div class="setClassDialog__tree flex_column">
        <div class="flex_row">
          <dog-input
            v-model="filterKey"
            @keyup.enter.native="change"
            class="flex_1"
            placeholder="请输入内容，回车搜索"
          ></dog-input>
          <dog-button
            type="primary"
            class="setClassDialog__tree__selectAll"
            @click="selectAll"
            >全选</dog-button
          >
        </div>
        <dog-scroll-box class="setClassDialog__tree__content flex_1">
          <el-tree
            ref="tree"
            :data="treeData"
            :props="{ label: 'name' }"
            show-checkbox
            node-key="id"
            :check-strictly="true"
            :default-expand-all="true"
            @check-change="checkTree"
          >
            <span
              slot-scope="{ node, data }"
              class="el-tree-node__label"
              :title="node.label"
              >{{ node.label }}
            </span>
          </el-tree>
        </dog-scroll-box>
      </div>
      <el-divider direction="vertical"></el-divider>
      <dog-button
        type="primary"
        class="setClassDialog__clearAll"
        @click="clearAll"
        >清空</dog-button
      >
      <div class="flex_column setClassDialog__selected">
        <h4>已选：</h4>
        <dog-scroll-box class="setClassDialog__selected__content flex_1">
          <div
            v-for="data in selectedData"
            :key="data.id"
            class="setClassDialog__selected__item"
          >
            <el-tag size="small" closable @close="removeSelected(data)">{{
              data.name
            }}</el-tag>
          </div>
        </dog-scroll-box>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import productService from '@/api/service/product';
  import classService from '@/api/service/class';

  export default {
    name: 'setClassDialog',
    data() {
      return {
        filterKey: '',
        treeData: [],
        selectedData: [],
        productId: null,
        companyId: null
      };
    },
    methods: {
      openFrame(companyId, productId) {
        this.companyId = companyId;
        this.productId = productId;
        this.$refs.hfDialog.open();
        productService
          .getProductClass({ productId })
          .then((res) => {
            this.selectedData = res;
          })
          .then(() => {
            this.getTreeData();
          });
      },
      getTreeData(searchKey) {
        classService
          .getClassTree({ searchKey, companyId: this.companyId })
          .then((res) => {
            this.treeData = res.map((item) => {
              return {
                ...item,
                disabled: true
              };
            });
            this.setCheckedKeys();
          });
      },
      setCheckedKeys() {
        let allDataKeys = [];
        const loopData = (data) => {
          data.forEach((item) => {
            const include = this.selectedData.find((subItem) => {
              return subItem.id === item.id;
            });
            if (include) {
              allDataKeys.push(item.id);
            }
            if (Array.isArray(item.children)) {
              loopData(item.children);
            }
          });
        };
        loopData(this.treeData);
        this.$refs.tree.setCheckedKeys(allDataKeys);
      },
      change() {
        this.getTreeData(this.filterKey);
      },
      removeSelected(data) {
        this.selectedData = this.selectedData.filter((item) => {
          return item.id !== data.id;
        });
        this.setCheckedKeys();
      },
      checkTree(data, checked) {
        const existIndex = this.selectedData.findIndex((item) => {
          return item.id === data.id;
        });
        // 选中且不存在
        if (checked && existIndex === -1) {
          this.selectedData.push(data);
        } else if (!checked && existIndex > -1) {
          // 未选中且存在
          this.selectedData.splice(existIndex, 1);
        }
      },
      selectAll() {
        let allDataKeys = [];
        const loopData = (data) => {
          data.forEach((item) => {
            if (!item.disabled) {
              allDataKeys.push(item.id);
            }
            if (Array.isArray(item.children)) {
              loopData(item.children);
            }
          });
        };
        loopData(this.treeData);
        this.$refs.tree.setCheckedKeys(allDataKeys);
      },
      clearAll() {
        this.$refs.tree.setCheckedKeys([]);
        this.selectedData = [];
      },
      confirm(done) {
        const params = {
          productId: this.productId,
          secondaryClassIds: this.selectedData.map((item) => {
            return item.id;
          })
        };
        productService
          .setProductClass(params)
          .then(() => {
            this.close();
            this.$emit('confirm');
          })
          .finally(done);
      },
      close() {
        this.$refs.hfDialog.close();
      },
      closed() {
        this.treeData = [];
        this.selectedData = [];
        this.productId = null;
        this.companyId = null;
        this.filterKey = '';
      }
    }
  };
</script>

<style lang="less" scoped>
  /* flex 样式 */
  .flex_column {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
  }
  .flex_row {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .flex_1 {
    flex: 1;
  }
  .flex_auto {
    flex: auto;
  }
  /* 弹窗分区滚动 */
  /deep/.dialog_zone_scroll {
    @content_height: 500px;

    height: @content_height;
    overflow: hidden;
    .hf-scroll-box div.el-scrollbar {
      position: relative;
    }
    .el-divider--vertical {
      height: @content_height;
    }
  }
  /* 组件样式 */
  .setClassDialog {
    &__tree {
      width: 400px;
      &__selectAll {
        margin-left: 10px;
      }
      &__content.hf-scroll-box {
        height: calc(~'100% - 32px');
      }
    }
    &__clearAll {
      position: absolute;
      top: 0;
      right: 0;
    }
    &__selected {
      h4 {
        margin-top: 8px;
        margin-bottom: 8px;
        line-height: 16px;
      }
      &__content.hf-scroll-box {
        height: calc(~'100% - 36px');
      }
      /deep/ .el-tag {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  /deep/ .el-tree-node__label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
