import { render, staticRenderFns } from "./setClassDialog.vue?vue&type=template&id=351b55f8&scoped=true&"
import script from "./setClassDialog.vue?vue&type=script&lang=js&"
export * from "./setClassDialog.vue?vue&type=script&lang=js&"
import style0 from "./setClassDialog.vue?vue&type=style&index=0&id=351b55f8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351b55f8",
  null
  
)

export default component.exports